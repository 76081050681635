import {
  ROAM_IMAGE_SIDENAV,
  ROAM_IMAGE_SVG,
} from "@app/core/const/app-roam.const";
import { WordDictionary } from "@app/shared/interfaces/word-dictionary.interface";

export const STORAGE_GLOBAL_SEARCH = "search-history";

export const setIconType: WordDictionary = {
  User: `${ROAM_IMAGE_SIDENAV}/userround.svg`,
  Owner: `${ROAM_IMAGE_SIDENAV}/userround.svg`,
  Tenant: `${ROAM_IMAGE_SIDENAV}/userround.svg`,
  Employee: `${ROAM_IMAGE_SIDENAV}/userround.svg`,
  Property: `${ROAM_IMAGE_SVG}/ic-home-black.svg`,
  Unit: `${ROAM_IMAGE_SIDENAV}/home.svg`,
  Vendor: `${ROAM_IMAGE_SIDENAV}/home.svg`,
  Request: `${ROAM_IMAGE_SIDENAV}/tasks.svg`,
  Task: `${ROAM_IMAGE_SIDENAV}/tasks.svg`,
  Violation: `${ROAM_IMAGE_SIDENAV}/violations.svg`,
  Transaction: `${ROAM_IMAGE_SIDENAV}/ic-payment-iht.svg`,
};
